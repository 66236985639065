@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

/* Font for hiding text */
@font-face {
  font-family: 'Hidden';
  src:url("https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf");
}

body {
  background-color: #111418;
  color: #fff;
  margin: 8px;
  font-family: "Source Code Pro", monospace;
  font-weight: 300;
  line-height: 1.1;
}

article {
  width: 45em;
  max-width: 98vw;
}

a:hover {
  text-decoration: underline;
}